<template>
  <div class="d-flex align-center flex-column">
    <v-card width="600" variant="outlined" class="ma-5">
      <v-card-item>
        <v-card-title>Загрузка файла каталога</v-card-title>
      </v-card-item>
      <v-card-text>
        <v-file-input
            accept="text/xml"
            name="catalog"
            @change="upload"
            label="File input" variant="underlined"/>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  methods: {
    upload(e) {
      const files = e.target.files
      if (files.length === 0) {
        return;
      }
      let formData = new FormData();
      formData.append("file", files[0]);
      console.log();
      fetch(`${window.host}/upload`, {
        method: 'POST',
        body: formData
      });
    }
  }
}
</script>

<style>

</style>
